import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Link } from 'gatsby';
import PlaceholderImage from "assets/images/thumbnail-portrait-generic.jpg";
import { getSrc, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Hero, Breadcrumb, FlagCard } from "components/ui";
import { useStrapiToolKitData, useStrapiToolkitPageData } from "hooks";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const TrainingLandingPage = ({ location }) => {

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }

    const { allStrapiToolkitModule } = useStrapiToolKitData();
    const { strapiTrainingToolKit } = useStrapiToolkitPageData();
    const seo = {
      metaTitle: strapiTrainingToolKit?.title,
      metaDescription: strapiTrainingToolKit?.description,
      metaKeywords: strapiTrainingToolKit?.keywords
    }
    const sortToolkitModules=toolkitModules=>{
      const toolkitModulesOrder=
      [
        "cw-module-1",
        "cw-module-2",
        "cw-module-3",
        "cw-module-4",
        "cw-module-5",
        "cw-module-6",
        "cw-module-7",
        "cw-special-topic-meth",
        "cw-special-topic-opioids",
        "cw-special-topic-prenatal"
      ]
      let sortedToolkitModules=toolkitModules.sort((currentToolkitModule,toolkitModule)=>toolkitModulesOrder.indexOf(currentToolkitModule?.slug)-toolkitModulesOrder.indexOf(toolkitModule?.slug))
      return sortedToolkitModules;
    }
    const setModuleName=slug=>{
      if(slug.includes("module-1")){
        return "mod1";
      } else if(slug.includes("module-2")){
        return "mod2";
      } else if(slug.includes("module-3")){
        return "mod3";
      } else if(slug.includes("module-4")){
        return "mod4";
      } else if(slug.includes("module-5")){
        return "mod5";
      } else if(slug.includes("module-6")){
        return "mod6";
      } else if(slug.includes("module-7")){
        return "mod7";
      } else if(slug.includes("topic-meth")){
        return "topic-meth";
      } else if(slug.includes("topic-opioids")){
        return "topic-opioids";
      } else if(slug.includes("topic-prenatal")){
        return "topic-prenatal";
      }
    } 
    return (
        <Layout location={location}>
          <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
          <div className="margin-bottom-5">
            {(strapiTrainingToolKit?.hero_image && strapiTrainingToolKit?.title) &&
            <Hero
            img={getSrc(strapiTrainingToolKit?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            isFixedHeight={false}
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={strapiTrainingToolKit?.title} />
              <h1 className="usa-hero__heading" aria-label={`Hero: ${strapiTrainingToolKit?.hero_image?.headline}`} name="main-content" id="main-content">{strapiTrainingToolKit?.hero_image?.headline}</h1>
            </Hero>
            }
          </div>
          <div className="grid-container">
            <div className="grid-row">
              <div className="grid-col-12">
                <h2>Overview</h2>
              </div>
              <div className="desktop:grid-col-7 desktop:padding-right-7 tablet:grid-col-12">
                {strapiTrainingToolKit?.overview &&
                 <div
                 dangerouslySetInnerHTML={{
                     __html: strapiTrainingToolKit?.overview?.data?.childMarkdownRemark?.html,
                     }}
                  className="unordered-list-style"
                 />
                }
              </div>
            </div>
          </div>
          <div className="grid-container">
            <div className="grid-row">
              <div className="desktop:grid-col-7 desktop:padding-right-7 tablet:grid-col-12">
                <h2>The Training Toolkit</h2>
                <p>Consists of 10 modules—seven foundational and three special topics:</p>
              </div>
              <div className="desktop:grid-col-5 tablet:grid-col-12 desktop:padding-left-7 desktop:margin-top-3 margin-bottom-2">
                {strapiTrainingToolKit?.glossary && <p><a href={`/files/toolkitpackage/misc/${strapiTrainingToolKit?.glossary?.name}`} aria-label="Open Glossary document in a new window" target="_blank" rel="noreferrer">Toolkit Glossary</a> (PDF {strapiTrainingToolKit?.glossary?.localFile?.prettySize.toUpperCase()})</p>}
                {/* {strapiTrainingToolKit?.resources && <p><a href={`/files/toolkitpackage/misc/${strapiTrainingToolKit?.resources?.name}`} aria-label="Open Resources document in a new window" target="_blank" rel="noreferrer">Resources</a> (PDF {strapiTrainingToolKit?.resources?.localFile?.prettySize.toUpperCase()})</p>} */}
                {strapiTrainingToolKit?.full_toolkit && <a href ={`/files/toolkitpackage/misc/${strapiTrainingToolKit?.full_toolkit?.name}`} aria-label="Download full training toolkit" className="usa-button primary-button padding-x-4" target="_blank" rel="noreferrer">Download Full Toolkit (ZIP {strapiTrainingToolKit?.full_toolkit?.localFile?.prettySize.toUpperCase()})</a>}
              </div>
            </div>
          </div>
          <div className="margin-bottom-5">
              <div className="grid-container">
                <div className="usa-card-group">
                  {sortToolkitModules(allStrapiToolkitModule?.nodes).map((resource, idx)=>{
                    return (
                    <FlagCard 
                    key={`toolkit-${idx + 1}`}
                    cardColor="bg-white"
                    grid="desktop:grid-col-12 min-height-flag-card-container card-detail card-mobile"
                    border="border-gray-10"
                    background="bg-white"
                    >
                      <FlagCard.Header>
                        {resource?.card_title &&
                          <h2 className="usa-card__heading">
                            {resource.card_title.length > 128 ? resource.card_title.substring(0,128) + "..." : resource.card_title}
                          </h2>
                        }
                      </FlagCard.Header>
                      <FlagCard.Media>
                        {resource?.thumbnail ?
                          <div className="usa-card__img">
                            <GatsbyImage
                            image={getImage(resource?.thumbnail?.localFile)}
                            alt={resource?.thumbnail?.alternativeText ?? ""}
                            imgStyle={{width: "205px", height:"265px"}}
                            style={{ width: "205px", height: "267px"}}
                            />
                          </div>
                        :
                          <div className="usa-card__img width-full">
                            <img
                            src={PlaceholderImage}
                            alt=""
                            style={{width: "205px", height:"265px"}}
                            />
                          </div>
                        }
                      </FlagCard.Media>
                      <FlagCard.Body>
                        {resource.card_description &&
                          <>
                            <span
                            className="card-text"
                            >
                              {resource.card_description.length > 230 ? resource.card_description.substring(0, 230) : resource.card_description}
                            </span>
                            ...
                            (<Link to={`${resource?.slug}`}>Read More</Link>)
                          </>       
                        }
                      </FlagCard.Body>
                      <FlagCard.Footer cardFooter="mobile:margin-x-auto">
                        <a 
                        href ={`/files/toolkitpackage/${setModuleName(resource.slug)}/${resource?.full_module?.name}`} 
                        aria-label={`Download ${resource.card_title} full module`} 
                        className="usa-button primary-button padding-x-5" 
                        target="_blank" 
                        rel="noreferrer"
                        >
                          Download Full Module{ ` (ZIP ${resource?.full_module?.localFile?.prettySize.toUpperCase()})`}
                        </a>
                      </FlagCard.Footer>
                    </FlagCard>
                  )})}
              </div>
            </div>
          </div>
        </Layout>
      )};

export default TrainingLandingPage;
